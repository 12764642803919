import FilialService from "@/core/services/filial.service";

export default {
    filters: {
        formatMoney(value) {
            if(!value) {
                value = 0
            }
            return Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
        },
        formatMoneyPYBR(value) {
            if(!value) {
                value = 0
            }
         const   filialId = parseInt(FilialService.getFilialId())
            let filiais = FilialService.getFiliais()
            let tipo = filiais[filialId].moeda
            console.log(tipo)
            // return Number(value).toLocaleString('es-ES', {style: 'currency', currency: 'PYG'})
            if(tipo == "guarani"){
                return Number(value.valor).toLocaleString('es-ES', {style: 'currency', currency: 'PYG'})
                }
                if(tipo == 'real'){
                    return Number(value).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
                    }
        },
        // formatMoneyMoeda(value) {
        //     if(!value.valor) {
        //         value.valor = 0
        //     }
        //     if(value.finan_moeda_id == 1){
        //     return Number(value.valor).toLocaleString('es-ES', {style: 'currency', currency: 'PYG'})
        //     }
        //     if(value.finan_moeda_id == 2){
        //         return Number(value.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
        //         }
        // },
        formatMoneyMoeda(value,tipo) {
            if(!value.valor) {
                value.valor = 0
            }
            if(tipo== 'real'){
            return Number(value.valor).toLocaleString('es-ES', {style: 'currency', currency: 'PYG'})
            }
            if(tipo == 'guarani'){
                return Number(value.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
                }
        }
    }
}